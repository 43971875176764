import './assets/css/bootstrap.min.css';
import './assets/css/all-fontawesome.min.css';
import './assets/css/animate.min.css';
import './assets/css/magnific-popup.min.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery-ui.min.css';
import './assets/css/jquery.timepicker.min.css';
import './assets/css/nice-select.min.css';
import './assets/css/style1.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import $ from 'jquery';

const Header = () => {
  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content />
      <meta name="keywords" content />
      <title>Drop In Taxi- Online Taxi Service </title>
      {/* <div className="preloader">
            <div className="loader-ripple">
              <div />
              <div />
            </div>
          </div> */}

      <header className="header">
        <div className="header-top">
          <div className="container">
            <div className="header-top-wrapper">
              <div className="header-top-left">
                <div className="header-top-contact">
                  <ul>
                    <li><a href="#"><i className="far fa-envelopes" />
                      <span className="__cf_email__" data-cfemail>info@dropintaxi.com</span></a></li>
                    <li><a href="tel:+919884444483"><i className="far fa-phone-volume" /> +91 98844 44483</a>
                    </li>
                    <li><a href="#"><i className="far fa-alarm-clock" /> 24 Hrs</a></li>
                  </ul>
                </div>
              </div>
              <div className="header-top-right">
                {/* <div class="header-top-NavLink">
                    <a href="#"><i class="far fa-arrow-right-to-bracket"></i> Login</a>
                    <a href="#"><i class="far fa-user-vneck"></i> Register</a>
                    </div> */}
                <div className="header-top-social">
                  <span>Follow Us: </span>
                  <a href="#"><i className="fab fa-facebook" /></a>
                  <a href="#"><i className="fab fa-twitter" /></a>
                  <a href="#"><i className="fab fa-instagram" /></a>
                  <a href="#"><i className="fab fa-NavLinkedin" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-navigation">
          {/* <nav className="navbar navbar-expand-lg">
                <div className="container position-relative">
                  <a className="navbar-brand" href="/">
                    <h4>DROP IN <span style={{color: '#FDB200'}}>TAXI</span></h4>
                  </a>
                  <div className="mobile-menu-right">
                    <div className="search-btn">
                      <button type="button" className="nav-right-NavLink"><i className="far fa-search" /></button>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-mobile-icon"><i className="far fa-bars" /></span>
                    </button>
                  </div>
                  <div className="collapse navbar-collapse" id="main_nav">
                    <ul className="navbar-nav">
                      <li className="nav-item dropdown">
                        <NavLink className="nav-link" exact activeClassName="active" to='/'>Home</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" activeClassName="active" to='/about'>About</NavLink>
                      </li>
                      <li className="nav-item ">
                      <NavLink className="nav-link" activeClassName="active" to='/traffic'>Traffic</NavLink>
                      </li>
                      <li className='nav-item'>
                      <NavLink className="nav-link" activeClassName="active" to='/contact'>Contact</NavLink>
                      </li>
                    </ul>
                    <div className="nav-right">
                      <div className="sidebar-btn">
                        <button type="button" className="nav-right-NavLink"><i className="far fa-bars-filter" /></button>
                      </div>
                    </div>
                  </div>
                </div>
              </nav> */}
          <Navbar bg="light" expand="lg" className='navbar navbar-expand-lg'>
            <div className="container position-relative">
              <Navbar.Brand href="/" className='navbar-brand' title="DropinTaxi.com">
				<img src="brand-logo.png" class="ds-logo"/>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="main_nav" />
              <Navbar.Collapse id="main_nav">
                <Nav className="mr-auto nav-item dropdown">
                  <NavLink className="nav-link" activeClassName="active" to='/'>Home</NavLink>
                  <NavLink className="nav-link" activeClassName="active" to='/about'>About</NavLink>
                  <NavLink className="nav-link" activeClassName="active" to='/tariff'>Tarffic</NavLink>
                  <NavLink className="nav-link" activeClassName="active" to='/contact'>Contact</NavLink>
                </Nav>
                {/* <Button variant="primary" className="mr-2"><i className="far fa-bars-filter" /></Button>
                <Button variant="primary"><i className="far fa-search" /></Button> */}
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
      </header>
    </div>
  );
}

export default Header;