const About = () => {
    return (
        <div>
            <div className="sidebar-popup">
            <div className="sidebar-wrapper">
                <div className="sidebar-content">
                <button type="button" className="close-sidebar-popup"><i className="far fa-xmark" /></button>
                <div className="sidebar-logo">
                    <img src="http://dropintaxi.com/assets/img/logo/logo.png" alt="" />
                </div>
                <div className="sidebar-about">
                    <h4>About Us</h4>
                    <p>There are many variations of passages available sure there majority have suffered alteration in
                    some form by injected humour or randomised words which don't look even slightly believable.</p>
                </div>
                <div className="sidebar-contact">
                    <h4>Contact Info</h4>
                    <ul>
                    <li>
                        <h6>Email</h6>
                        <a href="#"><i className="far fa-envelope" />info@dropintaxi.com</a>
                    </li>
                    <li>
                        <h6>Phone</h6>
                        <a href="tel:+919884444483"><i className="far fa-phone" />+91 9884 444 483</a>
                    </li>
                    <li>
                        <h6>Address</h6>
                        <a href="#"><i className="far fa-location-dot" />Chennai</a>
                    </li>
                    </ul>
                </div>
                <div className="sidebar-social">
                    <h4>Follow Us</h4>
                    <a href="#"><i className="fab fa-facebook" /></a>
                    <a href="#"><i className="fab fa-twitter" /></a>
                    <a href="#"><i className="fab fa-instagram" /></a>
                    <a href="#"><i className="fab fa-linkedin" /></a>
                </div>
                </div>
            </div>
            </div>
            <main className="main">
            <div className="site-breadcrumb" style={{background: 'url(http://dropintaxi.com/assets/img/breadcrumb/01.jpg)'}}>
                <div className="container">
                <h2 className="breadcrumb-title">About Us</h2>
                <ul className="breadcrumb-menu">
                    <li><a href="/">Home</a></li>
                    <li className="active">About Us</li>
                </ul>
                </div>
            </div>
            <div className="about-area py-120">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                    <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                        <div className="about-img">
                        <img src="assets/img/about/01.png" alt="" />
                        </div>
                        <div className="about-experience">
                        <div className="about-experience-icon">
                            <img src="assets/img/icon/taxi-booking.svg" alt="" />
                        </div>
                        <b>30 Years Of <br /> Quality Service</b>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="about-right wow fadeInRight" data-wow-delay=".25s">
                        <div className="site-heading mb-3">
                        <span className="site-title-tagline justify-content-start">
                            <i className="flaticon-drive" /> About Us
                        </span>
                        <h2 className="site-title">
                            We Provide Trusted <span>Cab Service</span> In The World
                        </h2>
                        </div>
                        <p className="about-text">
                        There are many variations of passages of Lorem Ipsum available, but the majority have
                        suffered alteration in some form, by injected humour.
                        </p>
                        <div className="about-list-wrapper">
                        <ul className="about-list list-unstyled">
                            <li>
                            At vero eos et accusamus et iusto odio.
                            </li>
                            <li>
                            Established fact that a reader will be distracted.
                            </li>
                            <li>
                            Sed ut perspiciatis unde omnis iste natus sit.
                            </li>
                        </ul>
                        </div>
                        <a href="about.php" className="theme-btn mt-4">Discover More<i className="fas fa-arrow-right" /></a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="counter-area">
                <div className="container">
                <div className="counter-wrapper mb-0">
                    <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="counter-box">
                        <div className="icon">
                            <img src="assets/img/icon/taxi-1.svg" alt="" />
                        </div>
                        <div>
                            <span className="counter" data-count="+" data-to={500} data-speed={3000}>500</span>
                            <h6 className="title">+ Available Taxi </h6>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="counter-box">
                        <div className="icon">
                            <img src="assets/img/icon/happy.svg" alt="" />
                        </div>
                        <div>
                            <span className="counter" data-count="+" data-to={900} data-speed={3000}>900</span>
                            <h6 className="title">+ Happy Clients</h6>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="counter-box">
                        <div className="icon">
                            <img src="assets/img/icon/driver.svg" alt="" />
                        </div>
                        <div>
                            <span className="counter" data-count="+" data-to={700} data-speed={3000}>700</span>
                            <h6 className="title">+ Our Drivers</h6>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="counter-box">
                        <div className="icon">
                            <img src="assets/img/icon/trip.svg" alt="" />
                        </div>
                        <div>
                            <span className="counter" data-count="+" data-to={1800} data-speed={3000}>1800</span>
                            <h6 className="title">+ Road Trip Done</h6>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="team-area py-120">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 mx-auto">
                    <div className="site-heading text-center">
                        <span className="site-title-tagline">Drivers</span>
                        <h2 className="site-title">Our Expert Drivers</h2>
                        <div className="heading-divider" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                    <div className="team-item wow fadeInUp" data-wow-delay=".25s">
                        <div className="team-img">
                        <img src="assets/img/team/01.jpg" alt="thumb" />
                        </div>
                        <div className="team-content">
                        <div className="team-bio">
                            <h5><a href="#">Alma Mcelroy</a></h5>
                            <span>Expert Driver</span>
                        </div>
                        </div>
                        <div className="team-social">
                        <a href="#"><i className="fab fa-facebook-f" /></a>
                        <a href="#"><i className="fab fa-twitter" /></a>
                        <a href="#"><i className="fab fa-linkedin-in" /></a>
                        <a href="#"><i className="fab fa-youtube" /></a>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="team-item wow fadeInDown" data-wow-delay=".25s">
                        <div className="team-img">
                        <img src="assets/img/team/02.jpg" alt="thumb" />
                        </div>
                        <div className="team-content">
                        <div className="team-bio">
                            <h5><a href="#">Marie Hooks</a></h5>
                            <span>Expert Driver</span>
                        </div>
                        </div>
                        <div className="team-social">
                        <a href="#"><i className="fab fa-facebook-f" /></a>
                        <a href="#"><i className="fab fa-twitter" /></a>
                        <a href="#"><i className="fab fa-linkedin-in" /></a>
                        <a href="#"><i className="fab fa-youtube" /></a>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="team-item wow fadeInUp" data-wow-delay=".25s">
                        <div className="team-img">
                        <img src="assets/img/team/03.jpg" alt="thumb" />
                        </div>
                        <div className="team-content">
                        <div className="team-bio">
                            <h5><a href="#">Daniel Nesmith</a></h5>
                            <span>Expert Driver</span>
                        </div>
                        </div>
                        <div className="team-social">
                        <a href="#"><i className="fab fa-facebook-f" /></a>
                        <a href="#"><i className="fab fa-twitter" /></a>
                        <a href="#"><i className="fab fa-linkedin-in" /></a>
                        <a href="#"><i className="fab fa-youtube" /></a>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="team-item wow fadeInDown" data-wow-delay=".25s">
                        <div className="team-img">
                        <img src="assets/img/team/04.jpg" alt="thumb" />
                        </div>
                        <div className="team-content">
                        <div className="team-bio">
                            <h5><a href="#">Gertrude Barrow</a></h5>
                            <span>Expert Driver</span>
                        </div>
                        </div>
                        <div className="team-social">
                        <a href="#"><i className="fab fa-facebook-f" /></a>
                        <a href="#"><i className="fab fa-twitter" /></a>
                        <a href="#"><i className="fab fa-linkedin-in" /></a>
                        <a href="#"><i className="fab fa-youtube" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="testimonial-area py-120">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 mx-auto">
                    <div className="site-heading text-center">
                        <span className="site-title-tagline"><i className="flaticon-drive" /> Testimonials</span>
                        <h2 className="site-title text-white">What Our Client <span>Say's</span></h2>
                        <div className="heading-divider" />
                    </div>
                    </div>
                </div>
                <div className="testimonial-slider owl-carousel owl-theme">
                    <div className="testimonial-single">
                    <div className="testimonial-content">
                        <div className="testimonial-author-img">
                        <img src="assets/img/testimonial/01.jpg" alt="" />
                        </div>
                        <div className="testimonial-author-info">
                        <h4>Sylvia Green</h4>
                        <p>Customer</p>
                        </div>
                    </div>
                    <div className="testimonial-quote">
                        <span className="testimonial-quote-icon"><i className="far fa-quote-right" /></span>
                        <p>
                        There are many variations of words suffered available to the have majority but the majority
                        suffer to alteration injected hidden the middle text.
                        </p>
                    </div>
                    <div className="testimonial-rate">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                    </div>
                    </div>
                    <div className="testimonial-single">
                    <div className="testimonial-content">
                        <div className="testimonial-author-img">
                        <img src="assets/img/testimonial/02.jpg" alt="" />
                        </div>
                        <div className="testimonial-author-info">
                        <h4>Gordo Novak</h4>
                        <p>Customer</p>
                        </div>
                    </div>
                    <div className="testimonial-quote">
                        <span className="testimonial-quote-icon"><i className="far fa-quote-right" /></span>
                        <p>
                        There are many variations of words suffered available to the have majority but the majority
                        suffer to alteration injected hidden the middle text.
                        </p>
                    </div>
                    <div className="testimonial-rate">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                    </div>
                    </div>
                    <div className="testimonial-single">
                    <div className="testimonial-content">
                        <div className="testimonial-author-img">
                        <img src="assets/img/testimonial/03.jpg" alt="" />
                        </div>
                        <div className="testimonial-author-info">
                        <h4>Reid Butt</h4>
                        <p>Customer</p>
                        </div>
                    </div>
                    <div className="testimonial-quote">
                        <span className="testimonial-quote-icon"><i className="far fa-quote-right" /></span>
                        <p>
                        There are many variations of words suffered available to the have majority but the majority
                        suffer to alteration injected hidden the middle text.
                        </p>
                    </div>
                    <div className="testimonial-rate">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                    </div>
                    </div>
                    <div className="testimonial-single">
                    <div className="testimonial-content">
                        <div className="testimonial-author-img">
                        <img src="assets/img/testimonial/04.jpg" alt="" />
                        </div>
                        <div className="testimonial-author-info">
                        <h4>Parker Jime</h4>
                        <p>Customer</p>
                        </div>
                    </div>
                    <div className="testimonial-quote">
                        <span className="testimonial-quote-icon"><i className="far fa-quote-right" /></span>
                        <p>
                        There are many variations of words suffered available to the have majority but the majority
                        suffer to alteration injected hidden the middle text.
                        </p>
                    </div>
                    <div className="testimonial-rate">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                    </div>
                    </div>
                    <div className="testimonial-single">
                    <div className="testimonial-content">
                        <div className="testimonial-author-img">
                        <img src="assets/img/testimonial/05.jpg" alt="" />
                        </div>
                        <div className="testimonial-author-info">
                        <h4>Heruli Nez</h4>
                        <p>Customer</p>
                        </div>
                    </div>
                    <div className="testimonial-quote">
                        <span className="testimonial-quote-icon"><i className="far fa-quote-right" /></span>
                        <p>
                        There are many variations of words suffered available to the have majority but the majority
                        suffer to alteration injected hidden the middle text.
                        </p>
                    </div>
                    <div className="testimonial-rate">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="download-area pt-120">
                <div className="container">
                <div className="download-wrapper">
                    <div className="row">
                    <div className="col-lg-6">
                        <div className="download-content">
                        <div className="site-heading mb-4">
                            <span className="site-title-tagline justify-content-start">
                            <i className="flaticon-drive" /> Get Our Web
                            </span>
                            <h2 className="site-title mb-10">Goto <span>Our DropinTaxi</span> web For Free</h2>
                            <p>
                            There are many variations of passages available but the majority have suffered in some form going to use a passage by injected humour.
                            </p>
                        </div>
                        <div className="download-btn">
                            <a href="https://dropintaxi.com/">
                            <i className="fab fa-google" />
                            <div className="download-btn-content">
                                <span>Get It On</span>
                                <strong>Web Access</strong>
                            </div>
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="download-img">
                    <img src="assets/img/download/01.png" alt="" />
                    </div>
                </div>
                </div>
            </div>
            <div className="partner pt-80 pb-80">
                <div className="container">
                <div className="partner-slider owl-carousel owl-theme">
                    <div className="partner-item">
                    <div className="partner-img">
                        <img src="assets/img/partner/01.png" alt="" />
                    </div>
                    </div>
                    <div className="partner-item">
                    <div className="partner-img">
                        <img src="assets/img/partner/02.png" alt="" />
                    </div>
                    </div>
                    <div className="partner-item">
                    <div className="partner-img">
                        <img src="assets/img/partner/03.png" alt="" />
                    </div>
                    </div>
                    <div className="partner-item">
                    <div className="partner-img">
                        <img src="assets/img/partner/01.png" alt="" />
                    </div>
                    </div>
                    <div className="partner-item">
                    <div className="partner-img">
                        <img src="assets/img/partner/02.png" alt="" />
                    </div>
                    </div>
                    <div className="partner-item">
                    <div className="partner-img">
                        <img src="assets/img/partner/03.png" alt="" />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </main>
        </div>
    );
}

export default About