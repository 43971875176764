import logo from './logo.svg';
import $ from 'jquery';
import './assets/css/all-fontawesome.min.css';
import './App.css';
import Header from './HeaderMain';
import Footer from './FooterMain';
import Main from './MainPage';
import Local from './LocalTrip';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Make sure to import the BrowserRouter
import About from './AboutPage';
import Booking from './BookingMain';
import Contact from './ContactPage';
import Traffic from './TrafficPage';
import RentalBooking from './RentalCarBookingPage';
import CarBook from './CarBookingPage';
import Round from './Round';
import Rental from './Rental';

function App() {
	
	
	
  return (
    <Router>
      <Header /> {/* Header appears on all pages */}
      <Routes>
        <Route path="/" element={<Main />} />
		<Route path="/home" element={<Main />} />
		<Route path="/round" element={<Round />} />
		<Route path="/rental" element={<Rental />} />
		<Route path="/local" element={<Local />} />
        <Route path="/about" element={<About />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tariff" element={<Traffic />} />
        <Route path="/rentalbooking" element={<RentalBooking />} />
        <Route path="/carBook" element={<CarBook />} />
        {/* Add more routes here */}
      </Routes>
      <Footer /> {/* Footer appears on all pages */}
    </Router>
  );
}

export default App;
