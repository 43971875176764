const Contact = () => {

      return (
        <div>
          {/*?php include('header.php');?*/}
          <div className="sidebar-popup">
            <div className="sidebar-wrapper">
              <div className="sidebar-content">
                <button type="button" className="close-sidebar-popup"><i className="far fa-xmark" /></button>
                <div className="sidebar-logo">
                  <img src="http://dropintaxi.com/assets/img/logo/logo.png" alt="" />
                </div>
                <div className="sidebar-about">
                  <h4>About Us</h4>
                  <p>There are many variations of passages available sure there majority have suffered alteration in
                    some form by injected humour or randomised words which don't look even slightly believable.</p>
                </div>
                <div className="sidebar-contact">
                  <h4>Contact Info</h4>
                  <ul>
                    <li>
                      <h6>Email</h6>
                      <a href="#"><i className="far fa-envelope" />info@dropintaxi.com</a>
                    </li>
                    <li>
                      <h6>Phone</h6>
                      <a href="tel:+21236547898"><i className="far fa-phone" />+91 9884 444 483</a>
                    </li>
                    <li>
                      <h6>Address</h6>
                      <a href="#"><i className="far fa-location-dot" />Chennai</a>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-social">
                  <h4>Follow Us</h4>
                  <a href="#"><i className="fab fa-facebook" /></a>
                  <a href="#"><i className="fab fa-twitter" /></a>
                  <a href="#"><i className="fab fa-instagram" /></a>
                  <a href="#"><i className="fab fa-linkedin" /></a>
                </div>
              </div>
            </div>
          </div>
          <main className="main">
            <div className="site-breadcrumb" style={{background: 'url(http://dropintaxi.com/assets/img/breadcrumb/01.jpg)'}}>
              <div className="container">
                <h2 className="breadcrumb-title">Contact Us</h2>
                <ul className="breadcrumb-menu">
                  <li><a href="/">Home</a></li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
            </div>
            <div className="contact-area py-120">
              <div className="container">
                <div className="contact-content">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="contact-info">
                        <div className="contact-info-icon">
                          <i className="fal fa-map-location-dot" />
                        </div>
                        <div className="contact-info-content">
                          <h5>Office Address</h5>
                          <p>Chennai</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="contact-info">
                        <div className="contact-info-icon">
                          <i className="fal fa-phone-volume" />
                        </div>
                        <div className="contact-info-content">
                          <h5>Call Us</h5>
                          <p>+91 9884 444 483</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="contact-info">
                        <div className="contact-info-icon">
                          <i className="fal fa-envelopes" />
                        </div>
                        <div className="contact-info-content">
                          <h5>Email Us</h5>
                          <p>info@dropintaxi.com</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="contact-info">
                        <div className="contact-info-icon">
                          <i className="fal fa-alarm-clock" />
                        </div>
                        <div className="contact-info-content">
                          <h5>Open Time</h5>
                          <p>Mon - Fri (08.00AM - 10.00PM)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-wrapper">
                  <div className="row">
                    <div className="col-lg-6 align-self-center">
                      <div className="contact-img">
                        <img src="assets/img/contact/01.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                      <div className="contact-form">
                        <div className="contact-form-header">
                          <h2>Get In Touch</h2>
                          <p>It is a long established fact that a reader will be distracted by the readable
                            content of a page randomised words slightly when looking at its layout. </p>
                        </div>
                        <form method="post" action="#" id="contact-form">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input type="text" className="form-control" name="name" placeholder="Your Name" required />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <input type="text" className="form-control" name="subject" placeholder="Your Subject" required />
                          </div>
                          <div className="form-group">
                            <textarea name="message" cols={30} rows={5} className="form-control" placeholder="Write Your Message" defaultValue={""} />
                          </div>
                          <button type="submit" className="theme-btn">Send
                            Message <i className="far fa-paper-plane" /></button>
                          <div className="col-md-12 mt-3">
                            <div className="form-messege text-success" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248756.07885882704!2d80.20901174999999!3d13.04752545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ea4f7d3361%3A0x6e61a70b6863d433!2sChennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1688211789418!5m2!1sen!2sin" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
          </main>
          {/*?php include('footer.php');?*/}
        </div>
      );
    }
export default Contact;