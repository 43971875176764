const CarBook = () => {
      return (
  
        <main className="main">
          <div className="hero-section">
            <div className="hero-slider owl-carousel owl-theme">
              <div className="hero-single" style={{background: 'url(assets/img/slider/slider-1.jpg)'}}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-12 col-lg-9 mx-auto">
                      <div className="hero-content text-center">
                        {/*<h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To*/}
                        {/*DROP IN TAXI!</h6>*/}
                        {/*<h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">*/}
                        {/*Book <span>Taxi</span> For Your Ride*/}
                        {/*</h1>*/}
                        {/*<div class="hero-btn justify-content-center" data-animation="fadeInUp" data-delay="1s">*/}
                        {/*<a href="#" class="theme-btn">About More<i class="fas fa-arrow-right"></i></a>*/}
                        {/*<a href="#" class="theme-btn theme-btn2">Learn More<i class="fas fa-arrow-right"></i></a>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-single" style={{background: 'url(assets/img/slider/slider-2.jpg)'}}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-12 col-lg-9 mx-auto">
                      <div className="hero-content text-center">
                        {/*<h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To*/}
                        {/*DROP IN TAXI!</h6>*/}
                        {/*<h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">*/}
                        {/*Book <span>Taxi</span> For Your Ride*/}
                        {/*</h1>*/}
                        {/*<div class="hero-btn justify-content-center" data-animation="fadeInUp" data-delay="1s">*/}
                        {/*<a href="#" class="theme-btn">About More<i class="fas fa-arrow-right"></i></a>*/}
                        {/*<a href="#" class="theme-btn theme-btn2">Learn More<i class="fas fa-arrow-right"></i></a>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-single" style={{background: 'url(assets/img/slider/slider-3.jpg)'}}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-12 col-lg-9 mx-auto">
                      <div className="hero-content text-center">
                        {/*<h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To*/}
                        {/*    DROP IN TAXI!</h6>*/}
                        {/*<h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">*/}
                        {/*Book <span>Taxi</span> For Your Ride*/}
                        {/*</h1>*/}
                        {/*<div class="hero-btn justify-content-center" data-animation="fadeInUp" data-delay="1s">*/}
                        {/*<a href="#" class="theme-btn">About More<i class="fas fa-arrow-right"></i></a>*/}
                        {/*<a href="#" class="theme-btn theme-btn2">Learn More<i class="fas fa-arrow-right"></i></a>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="taxi-area py-120">
            <div className="container">
              {/*<div class="row">*/}
              {/*<div class="col-lg-6 mx-auto">*/}
              {/*<div class="site-heading text-center">*/}
              {/*<span class="site-title-tagline">Our Taxi</span>*/}
              {/*<h2 class="site-title">Let's Book Available Taxi</h2>*/}
              {/*<div class="heading-divider"></div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              <div className="booking-area top-book" style={{marginTop: '0px !important', marginBottom: '20px'}}>
                <div className="container">
                  <div className="booking-form" style={{marginTop: '-610px'}}>
                    <div className="filter-controls">
                      <ul className="filter-btns">
                        <li data-filter=".cat5">Local Trip</li>
                        <li className="active" data-filter=".cat1">Outstation Trip</li>
                      </ul>
                      <ul className="filter-btns">
                        <li data-filter=".cat2">LOCAL TRIP</li>
                        <a href="rentalcarbook.php"><li data-filter=".cat3">RENTAL TRIP</li></a>
                        <li data-filter=".cat5">DROP TRIP</li>
                        <li className="active" data-filter=".cat1">ROUND TRIP</li>
                        {/*<li data-filter=".cat4">MULTI TRIP</li>*/}
                      </ul>
                    </div>
                    <h4 className="booking-title">Book Your Ride</h4>
                    <form action="carbook.php" method="get">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Pick Up Location</label>
                            <select className="select" name="pickuploc" id="pickuploc" required>
                              <option value>Choose Pickup Location</option>
                              {/*?php 
  $selcity=$db-*/}query("select * from cities");
                              while($selcitydet=$selcity-&gt;fetch_assoc()){'{'} ?&gt;
                              <option value="<?php echo $selcitydet['id'];?>" data-name="<?php echo $selcitydet['name'];?>">{/*?php echo $selcitydet['name'];?*/}</option>
                              {/*?php } ?*/}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Drop Off Location</label>
                            <select className="select" name="dropoffloc" id="dropoffloc" required>
                              <option value>Choose Drop Off Location</option>
                              {/*?php 
  $selcity1=$db-*/}query("select * from cities");
                              while($selcitydet1=$selcity1-&gt;fetch_assoc()){'{'} ?&gt;
                              <option value="<?php echo $selcitydet1['id'];?>" data-name="<?php echo $selcitydet1['name'];?>">{/*?php echo $selcitydet1['name'];?*/}</option>
                              {/*?php } ?*/}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>No Of Days</label>
                            <input type="text" className="form-control" id="noofdays" required name="noofdays" defaultValue={1} placeholder="No Of Days" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Mobile No</label>
                            <input type="text" className="form-control" id="mobileno" required name="mobileno" maxLength={10} defaultValue placeholder="Enter Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <button type="submit" className="theme-btn" id="button3" style={{marginTop: '32px'}}>
                              Book Taxi
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row filter-box">
                {/*?php 
  $selcars=$db-*/}query("select * from cars");
                while($cardet=$selcars-&gt;fetch_assoc())
                {'{'}
                $cat='cat'.$cardet['car_type'];
                ?&gt;
                <div className="col-md-6 col-lg-4 filter-item <?php echo $cat;?>" style={{display: 'none'}}>
                </div>
                {/*?php } ?*/}
              </div>
            </div>
          </div>
          {/*?php include("config.php")?*/}
          {/*?php
  $popup=0;
  if(isset($_GET['pickuploc']) && isset($_GET['dropoffloc']) && isset($_GET['noofdays']) && isset($_GET['mobileno']))
  {
      
  }
  else
  {
      header("Location: https://techparkinfosolutions.com/drop-in-taxi/index.php");
  }
  if(isset($_SERVER['HTTPS']) && $_SERVER['HTTPS'] === 'on')   
       $url = "https://";   
  else  
       $url = "http://";   
  // Append the host(domain name, ip) to the URL.   
  $url.= $_SERVER['HTTP_HOST'];   
  
  // Append the requested resource location to the URL   
  $url.= $_SERVER['REQUEST_URI'];    
  $urlsub=explode("?",$url);
  $urlval=$urlsub[1];
  if(isset($_POST['car_id']))
  {
       $carid=$_POST['car_id'];
       $distance_id=$_POST['citykm'];
       $tripfair=$_POST['tripfairvalue'];
       $pick_location=$_POST['pick_location'];
       $drop_location=$_POST['drop_location'];
       $pick_up_date=$_POST['pick_up_date'];
       $pick_up_time=$_POST['pick_up_time'];
       $mobile=$_POST['mobile'];
       $email=$_POST['email'];
       if($_POST['no_of_days'] */} 1)
          {'{'}
          $no_of_days=$_POST['no_of_days'];
          {'}'}
          else
          {'{'}
          $no_of_days=1;
          {'}'}
          $cab_model=$_POST['cab_model'];
          $cardet = $db-&gt;query("SELECT * FROM `cars` WHERE `id`='$carid'")-&gt;fetch_object();
          $passengers=$cardet-&gt;passenger_count;
          $car_type=$cardet-&gt;car_type;
          $sql = "INSERT INTO bookings (car_id,distance_id,pick_location,drop_location,passengers,cab_type,pick_up_date,pick_up_time,cab_model,trip_fair,no_of_days,mobileno,email)
          VALUES ('$carid','$distance_id','$pick_location','$drop_location','$passengers','$car_type','$pick_up_date','$pick_up_time','$cab_model','$tripfair','$no_of_days','$mobile','$email')";
          if ($db-&gt;query($sql) === TRUE) {'{'}
          //   echo "Car Booking successfully";
          $popup=1;
          {'}'} else {'{'}
          //   echo "Error: " . $sql . "<br />" . $db-&gt;error;
          $popup=2;
          {'}'}
          {'}'}
          ?&gt;
          <style dangerouslySetInnerHTML={{__html: "\n    body\n    {\n        height: 100%;\n        overflow: hidden;\n    }\n" }} />
          <div className="sidebar-popup">
            <div className="sidebar-wrapper">
              <div className="sidebar-content">
                <button type="button" className="close-sidebar-popup"><i className="far fa-xmark" /></button>
                <div className="sidebar-logo">
                  {/* <img src="assets/img/logo/logo.png" alt> */}
                  <h4>DROP IN <span style={{color: '#FDB200'}}>TAXI</span></h4>
                </div>
                <div className="sidebar-about">
                  <h4>About Us</h4>
                  <p>There are many variations of passages available sure there majority have suffered alteration in
                    some form by injected humour or randomised words which don't look even slightly believable.</p>
                </div>
                <div className="sidebar-contact">
                  <h4>Contact Info</h4>
                  <ul>
                    <li>
                      <h6>Email</h6>
                      <a href><i className="far fa-envelope" /><span className="__cf_email__">info@dropintaxi.com</span></a>
                    </li>
                    <li>
                      <h6>Phone</h6>
                      <a href="tel:+919884444483"><i className="far fa-phone" />+91 98844 44483</a>
                    </li>
                    <li>
                      <h6>Address</h6>
                      <a href="#"><i className="far fa-location-dot" />CHENNAI</a>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-social">
                  <h4>Follow Us</h4>
                  <a href="#"><i className="fab fa-facebook" /></a>
                  <a href="#"><i className="fab fa-twitter" /></a>
                  <a href="#"><i className="fab fa-instagram" /></a>
                  <a href="#"><i className="fab fa-linkedin" /></a>
                </div>
              </div>
            </div>
          </div>
          <main className="main">
            <div className="taxi-area py-120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 mx-auto">
                    <div className="site-heading text-center">
                      <span className="site-title-tagline">Our Taxi</span>
                      <h2 className="site-title">Let's Book Available Taxi</h2>
                      <div className="heading-divider" />
                    </div>
                  </div>
                </div>
                <div className="filter-controls">
                  <ul className="filter-btns">
                    <li data-filter=".cat5">DROP TRIP</li>
                    <li data-filter=".cat1">ROUND TRIP</li>
                    <li data-filter=".cat2">LOCAL TRIP</li>
                    <li data-filter=".cat4">MULTI TRIP</li>
                    <li className="active" data-filter=".catall">ALL TRIP</li>
                  </ul>
                </div>
                <div className="row filter-box">
                  {/*?php 
  $selcars=$db-*/}query("select * from cars");
                  while($cardet=$selcars-&gt;fetch_assoc())
                  {'{'}
                  if($cardet['air_condition'] == 1){'{'}
                  $air_condition='Yes';
                  {'}'}
                  else{'{'}
                  $air_condition='No';
                  {'}'}
                  $fromcity=$_GET['pickuploc'];
                  $tocity=$_GET['dropoffloc'];
                  $noofdays=$_GET['noofdays'];
                  $fromcitydet = $db-&gt;query("SELECT * FROM `cities` WHERE `id`='$fromcity'")-&gt;fetch_object();
                  $tocitydet = $db-&gt;query("SELECT * FROM `cities` WHERE `id`='$tocity'")-&gt;fetch_object();
                  $selcitykm = $db-&gt;query("SELECT * FROM `distance` WHERE (`fomcity`='$fromcity' and `tocity`='$tocity') or (`fomcity`='$tocity' and `tocity`='$fromcity') ORDER BY id DESC LIMIT 1")-&gt;fetch_object();
                  $citykm=$selcitykm-&gt;km_range;
                  $citykmvalue=$selcitykm-&gt;id;
                  $amntfair=$cardet['rup_per_km']*$citykm;
                  if($cardet['car_type'] == 1){'{'}
                  $tripType='Round Trip';
                  $triptypeval='1';
                  $citykm=$citykm*2;
                  $amntfair=($amntfair*2);
                  //$amntfair=($amntfair*2)*$noofdays;
                  {'}'}
                  else{'{'}
                  $tripType='Direct Trip';
                  $triptypeval='2';
                  $amntfair=$amntfair;
                  //$amntfair=$amntfair*$noofdays;
                  $citykm=$citykm;
                  {'}'}
                  if($cardet['gps_navigation'] == 1){'{'}
                  $gps_navigation='Yes';
                  {'}'}
                  else{'{'}
                  $gps_navigation='No';
                  {'}'}
                  if($cardet['driver_chossing'] == 1){'{'}
                  $driver_chossing='Add';
                  {'}'}
                  else{'{'}
                  $driver_chossing='No';
                  {'}'}
                  if($cardet['taxi_doors'] == 1){'{'}
                  $taxi_doors='Ex';
                  {'}'}
                  else{'{'}
                  $taxi_doors='In';
                  {'}'}
                  $cat='cat'.$cardet['car_type'];
                  ?&gt;
                  <div className="col-md-6 col-lg-4 filter-item catall <?php echo $cat;?>">
                    <div className="taxi-item">
                      <div className="taxi-img">
                        <img src="taxi-adminpanel/assets/images/cars/<?php echo $cardet['image'];?>" alt="" />
                      </div>
                      <div className="taxi-content">
                        <div className="taxi-head">
                          <h4>{/*?php echo $cardet['name'];?*/}</h4>
                          <h4><span>(₹){/*?php echo $cardet['rup_per_km'];?*/}/-km</span></h4>
                          <h4><span className="amnt" style={{color: 'red'}}>Trip Fair-(₹){/*?php echo $amntfair;?*/}/-</span></h4>
                        </div>
                        <div className="taxi-feature">
                          <ul>
                            <li><i className="far fa-person-seat" /> Passengers: <span>{/*?php echo $cardet['passenger_count'];?*/}</span></li>
                            <li><i className="far fa-suitcase-rolling" /> Luggage Carry: <span>{/*?php echo $cardet['luggage_carry'];?*/}</span></li>
                            <li><i className="far fa-car-side-bolt" /> Toll-State Tax: <span>{/*?php echo $taxi_doors;?*/}</span></li>
                            <li><i className="far fa-heat" /> Air Condition: <span>{/*?php echo $air_condition;?*/}</span></li>
                            <li><i className="far fa-map-location-dot" /> GPS Navigation: <span>{/*?php echo $gps_navigation;?*/}</span>
                            </li>
                            <li><i className="far fa-car-side-bolt" /> Additional Km: <span>₹{/*?php echo $cardet['additonal_km'];?*/}/-</span></li>
                            <li><i className="far fa-user-pilot" /> Driver Charges: <span>{/*?php echo $driver_chossing;?*/}</span></li>
                          </ul>
                        </div>
                        <button type="button" className="theme-btn button1" id="button1<?php echo $cardet['id'];?>" data-triptype="<?php echo $tripType;?>" data-triptypeval="<?php echo $triptypeval;?>" data-perkm="<?php echo $cardet['rup_per_km'];?>" data-id="<?php echo $cardet['id'];?>">
                          Book Taxi Now
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*?php } ?*/}
                </div>
              </div>
            </div>
            <div className="modal" id="myModal">
              <div className="modal-dialog" style={{maxWidth: '100% !important'}}>
                <div className="modal-content" style={{width: '75% !important', margin: '0 auto'}}>
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h4 className="modal-title">Book Your Ride</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" />
                  </div>
                  <form action="#" method="post">
                    {/* Modal body */}
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Pick Up Location</label>
                            <input type="text" className="form-control" id="pick_locationname" name="pick_locationname" defaultValue="<?php echo $fromcitydet->name;?>" readOnly placeholder="Type Location" />
                            <input type="hidden" name="pick_location" id="pick_location" defaultValue="<?php echo $fromcity;?>" />
                            <i className="far fa-location-dot" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Drop Off Location</label>
                            <input type="text" className="form-control" id="drop_locationname" name="drop_locationname" defaultValue="<?php echo $tocitydet->name;?>" readOnly placeholder="Type Location" />
                            <input type="hidden" name="drop_location" id="drop_location" defaultValue="<?php echo $tocity;?>" />
                            <i className="far fa-location-dot" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>City Km</label>
                            <input type="text" className="form-control" id="citykm" defaultValue name="citykmvalue" readOnly placeholder="Type citykmvalue" />
                            <input type="hidden" name="citykm" id="citykmvalue" defaultValue />
                            <i className="far fa-location-dot" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Trip Fair Approximate</label>
                            <input type="text" className="form-control" id="trpifair" defaultValue name="trpifair" readOnly placeholder="Type trpifair" />
                            <input type="hidden" name="tripfairvalue" id="tripfairvalue" defaultValue />
                            <i className="far fa-location-dot" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Trip Type</label>
                            <input type="text" className="form-control" id="cab_modelname" defaultValue name="cab_modelname" readOnly placeholder="Trip Types" />
                            <input type="hidden" name="cab_model" id="cab_model" defaultValue />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>No Of Days</label>
                            <input type="text" className="form-control" id="noofdays" defaultValue="<?php echo $noofdays;?>" name="noofdays" readOnly placeholder="No Of Days" />
                            <input type="hidden" name="no_of_days" id="no_of_days" defaultValue="<?php echo $noofdays;?>" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Mobile</label>
                            <input type="text" className="form-control" id="mobile" defaultValue="<?php echo $_GET['mobileno'];?>" name="mobile" required placeholder="Mobile No" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Email</label>
                            <input type="text" className="form-control" id="email" name="email" required placeholder="Email Id" />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Pick Up Date</label>
                            <input type="date" name="pick_up_date" className="form-control" required placeholder="MM/DD/YY" />
                            <i className="far fa-calendar-days" />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Pick Up Time</label>
                            <input type="text" name="pick_up_time" className="form-control" required placeholder="00:00 AM" />
                            <i className="far fa-clock" />
                          </div>
                        </div>
                      </div>
                      <input type="hidden" name="car_id" id="car_id" />
                      <input type="hidden" name="triprateperkm" id="triprateperkm" defaultValue />
                    </div>
                    {/* Modal footer */}
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-danger">Book Taxi</button>
                    </div>
                  </form>
                  <input type="hidden" name="urlval" id="urlval" defaultValue="<?php echo $urlval;?>" />
                </div>
              </div>
            </div>
          </main>
          {/*?php include("footer.php")?*/}
        </main>
      );
    }

export default CarBook;