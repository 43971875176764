import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

const RentalBooking = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pickupLoc = queryParams.get('pickuploc');
  const dropoffLoc = queryParams.get('dropoffloc');
  const noOfDays = queryParams.get('noofdays');
  const mobileNo = queryParams.get('mobileno');
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
      try {
          const response = await axios.get(`https://dropintaxi.com/taxi-adminpanel/Api/Dropdet/dist?type=1&from=${pickupLoc}&to=${dropoffLoc}`)
          const da = response.data;
          setData(da['distance'])
          // console.log(data);
          setRefresh(false);
        } catch (error) {
          console.error('Error fetching markers:', error);
        }
      }
    useEffect(() => {
      fetchData();
    }, [refresh]);


      return (
        <div>
          <div className="taxi-rate py-120">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mx-auto">
                  <div className="site-heading text-center">
                    <span className="site-title-tagline">Taxi Rate</span>
                    <h2 className="site-title">Our Rental Taxi Rate For You</h2>
                    <div className="heading-divider" />
                  </div>
                </div>
              </div>
              <div className="row">
                {/*?php 
  $selcars=$db-*/}query("select * from carsnew");
                while($cardet=$selcars-&gt;fetch_assoc())
                {'{'}
                if($cardet['car_type'] == 1){'{'}
                $tripType='Round Trip';
                $triptypeval='1';
                {'}'}
                else{'{'}
                $tripType='Direct Trip';
                $triptypeval='2';
                {'}'}
                if($cardet['taxi_doors'] == 1){'{'}
                $taxi_doors='Ex';
                {'}'}
                else{'{'}
                $taxi_doors='In';
                {'}'}
                ?&gt;
                <div className="col-md-6 col-lg-4">
                  <div className="rate-item wow fadeInUp" data-wow-delay=".25s">
                    <div className="rate-header">
                      <div className="rate-img">
                        <img src="taxi-adminpanel/assets/images/cars/<?php echo $cardet['image'];?>" alt="" />
                      </div>
                    </div>
                    <div className="rate-header-content">
                      <h4>{/*?php echo $cardet['name'];?*/}</h4>
                      <p className="rate-duration">One Time Payment</p>
                    </div>
                    <div className="rate-content">
                      <div className="rate-icon">
                        <img src="taxi-adminpanel/assets/images/cars/<?php echo $cardet['image'];?>" alt="" />
                      </div>
                      <div className="rate-feature">
                        <ul>
                          <li><i className="far fa-check-double" /> Base Charge: <span>₹{/*?php echo $cardet['base_charge'];?*/}</span></li>
                          <li><i className="far fa-check-double" /> Distance Allowance: <span>{/*?php echo $cardet['dist_allowance'];?*/}km</span></li>
                          <li><i className="far fa-check-double" /> Estimate Hours: <span>{/*?php echo $cardet['estimate_hours'];?*/}Hrs</span></li>
                          <li><i className="far fa-check-double" /> Estimate Hours: <span>{/*?php echo $cardet['estimate_hours'];?*/}</span></li>
                          <li><i className="far fa-check-double" /> Extra Charge Per Km: <span>₹{/*?php echo $cardet['extra_km'];?*/}</span></li>
                          <li><i className="far fa-check-double" /> Extra Charge Per Hours: <span>₹{/*?php echo $cardet['extra_hours'];?*/}</span></li>
                          <li><i className="far fa-check-double" /> Toll-Parking: <span>{/*?php echo $taxi_doors;?*/}</span></li>
                        </ul>
                        <button type="button" className="theme-btn button1" id="button1<?php echo $cardet['id'];?>" data-triptype="<?php echo $tripType;?>" data-triptypeval="<?php echo $triptypeval;?>" data-id="<?php echo $cardet['id'];?>">
                          Book Taxi Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*?php } ?*/}
              </div>
            </div>
          </div>
          <div className="modal" id="myModal">
            <div className="modal-dialog" style={{maxWidth: '100% !important'}}>
              <div className="modal-content" style={{width: '75% !important', margin: '0 auto'}}>
                {/* Modal Header */}
                <div className="modal-header">
                  <h4 className="modal-title">Book Your Ride</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" />
                </div>
                <form action="#" method="post">
                  {/* Modal body */}
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Pick Up Location</label>
                          <select className="select" name="pick_location" id="pick_location">
                            <option value>Choose Pickup Location</option>
                            {/*?php 
  $selcity=$db-*/}query("select * from cities");
                            while($selcitydet=$selcity-&gt;fetch_assoc()){'{'} ?&gt;
                            <option value="<?php echo $selcitydet['id'];?>" data-name="<?php echo $selcitydet['name'];?>">{/*?php echo $selcitydet['name'];?*/}</option>
                            {/*?php } ?*/}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Trip Type</label>
                          <input type="text" className="form-control" id="cab_modelname" defaultValue name="cab_modelname" readOnly placeholder="Trip Types" />
                          <input type="hidden" name="cab_model" id="cab_model" defaultValue />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Mobile</label>
                          <input type="text" className="form-control" id="mobile" defaultValue="<?php echo $_GET['mobileno'];?>" name="mobile" required placeholder="Mobile No" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input type="text" className="form-control" id="email" name="email" required placeholder="Email Id" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Pick Up Date</label>
                          <input type="date" name="pick_up_date" className="form-control date-picker" placeholder="MM/DD/YY" />
                          <i className="far fa-calendar-days" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Pick Up Time</label>
                          <input type="text" name="pick_up_time" className="form-control time-picker" placeholder="00:00 AM" />
                          <i className="far fa-clock" />
                        </div>
                      </div>
                    </div>
                    <input type="hidden" name="car_id" id="car_id" />
                    <input type="hidden" name="no_of_days" id="no_of_days" />
                  </div>
                  {/* Modal footer */}
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-danger" data-bs-dismiss="modal">Book Taxi</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/*?php include("footer.php")?*/}
        </div>
      );
    }
  
export default RentalBooking;
