import './assets/css/bootstrap.min.css';
import './assets/css/all-fontawesome.min.css';
import './assets/css/animate.min.css';
import './assets/css/magnific-popup.min.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery-ui.min.css';
import './assets/css/jquery.timepicker.min.css';
import './assets/css/nice-select.min.css';
import './assets/css/style1.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';

const Footer = () => {
      return (
        <div>
          <footer className="footer-area">
            <div className="footer-widget">
              <div className="container">
                <div className="row footer-widget-wrapper pt-120 pb-70">
                  <div className="col-md-4 col-lg-4">
                    <div className="footer-widget-box about-us">
                      <a href="#" className="footer-logo">
                        <h4>DROP IN <span style={{color: '#FDB200'}}>TAXI</span></h4>
                      </a>
                      <p className="mb-3">
                        We are many variations of passages available but the majority have suffered alteration
                        in some form by injected humour words believable.
                      </p>
                      <ul className="footer-contact">
                        <li><a href="tel:+919884444483"><i className="far fa-phone" />+91 98844 44483</a></li>
                        <li><i className="far fa-map-marker-alt" />CHENNAI</li>
                        <li><a href><i className="far fa-envelope" /><span>info@dropintaxi.com</span></a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <div className="footer-widget-box list">
                      <h4 className="footer-widget-title">Quick Links</h4>
                      <ul className="footer-list">
                      <li><NavLink className="nav-link" activeClassName="active" to='/'><i className="fas fa-caret-right" /> Home</NavLink></li>
                      <li><NavLink className="nav-link" activeClassName="active" to='/about'><i className="fas fa-caret-right" /> About</NavLink></li>
                      <li><NavLink className="nav-link" activeClassName="active" to='/tariff'><i className="fas fa-caret-right" /> Tarffic</NavLink></li>
                      <li><NavLink className="nav-link" activeClassName="active" to='/contact'><i className="fas fa-caret-right" /> Contact</NavLink></li>
                        {/* <li><a href="/"><i className="fas fa-caret-right" /> Home</a></li>
                        <li><a href="/about"><i className="fas fa-caret-right" /> About Us</a></li>
                        <li><a href="/tariff"><i className="fas fa-caret-right" /> Tariff</a></li>
                        <li><a href="/contact"><i className="fas fa-caret-right" /> Contact</a></li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <div className="footer-widget-box list">
                      <h4 className="footer-widget-title">Newsletter</h4>
                      <div className="footer-newsletter">
                        <p>Subscribe Our Newsletter To Get Latest Update And News</p>
                        <div className="subscribe-form">
                          <form action="#">
                            <input type="email" className="form-control" placeholder="Your Email" />
                            <button className="theme-btn" type="submit">
                              Subscribe Now <i className="far fa-paper-plane" />
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <p className="copyright-text">
                      © Copyright <span id="date" /> <a href="#"> DROP IN TAXI </a> All Rights Reserved @ Developed By D10 Technology.
                    </p>
                  </div>
                  <div className="col-md-6 align-self-center">
                    <ul className="footer-social">
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                      <li><a href="#"><i className="fab fa-youtube" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <a href="#" id="scroll-top"><i className="far fa-arrow-up" /></a>
        </div>
      );
    }

    export default Footer;