import axios from "axios";
import $ from 'jquery';
import './assets/css/all-fontawesome.min.css';
import { useEffect, useState, useRef } from "react";
import Modal from 'react-modal';
import './index.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css'; // Import CSS for styling

import { Outlet, Link } from "react-router-dom";
import swal from 'sweetalert';

			$.get("https://dropintaxi.com/server.php?task=visit_count",
			function(data)
			{
				if(data)
				{
					try
					{
						
					}
					catch(err)
					{
						
					}
					
				}
			});
			


const Main = () => {
	const [value, setValue] = useState(null);	
	const [CarType, setCarType] = useState("SEDAN");
	const [startDate, setStartDate] = useState(new Date());
	const [BaseCharge,setBaseCharge] = useState(14*130);
	const [DriverBata,setDriverBata] = useState(400);
	const [TotalRate,setTotalRate] = useState((14*130)+400);
	const [TravelType, setTravelType] = useState("");
	const [Distance, setDistance] = useState(130);
	const [RatePerKm,setRatePerKm] = useState(14);
	
	const [From,setFrom] = useState("");
	const [To,setTo] = useState("");
	
	
		$.get("https://dropintaxi.com/server.php?task=get_cities",
			{
				
			},
			function(data)
			{
				if(data)
				{
					try
					{
						var arr = JSON.parse(data);
						var outp = '<option value="">Choose...</option>';
						
						for(var i=0; i<arr['cities'].length; i++)
						{
							outp = outp + '<option value="'+arr['cities'][i].id+'">'+arr['cities'][i].name+'</option>';
						}
						
						$("input[name='from']").html(outp);
						$("input[name='to']").html(outp);
						
						$("input[name='from']").val(From);
						$("input[name='to']").val(To);
						
						//$("input[name='from']").select2();
						//$("input[name='to']").select2();
					}
					catch(err)
					{
						alert(err.message);
					}
					
				}
			});
	
	const choose_car_type = (e) => 
	{
		$(document).ready(
		function()
		{
			$("#ds-selected-car").addClass("d-none");
			setCarType(e.target.getAttribute("data-value"));
			var ele = e.target;
			$(".ds-car-images").find(".ds-car").removeClass("selected");
			$(ele).closest(".ds-car").addClass("selected");
			$("#sel-car-type").val(e.target.getAttribute("data-value"));
			setBaseCharge(parseInt(e.target.getAttribute("data-rate-per-km"))*Distance) //per 130km
			setTotalRate((parseInt(e.target.getAttribute("data-rate-per-km"))*Distance)+DriverBata);
			setRatePerKm(e.target.getAttribute("data-rate-per-km"));
			$("#ds-selected-car").attr("src",e.target.getAttribute("data-value").toLowerCase()+".png");
			$("#ds-selected-car").removeClass("d-none");
			
		});
		
	}
	
	 const setFromLocation = () =>
	  {
		  if($("input[name='from']").val() != "" && $("input[name='to']").val() != "")
		  {
			  if($("input[name='from']").val() == $("input[name='to']").val())
			  {
				  swal ( "Oops" ,  "Dont Same Cities are from and to location" ,  "error" );
				  $("input[name='from']").val("");
				  $("input[name='to']").val("");				  
			  }
			  else
			  {
				  var outp = '<input type="hidden" name="from_city" value="'+$("input[name='from']").val()+'"><input type="hidden" name="to_city" value="'+$("input[name='to']").val()+'">';
				  
				  $("form").prepend(outp);
				  
				  $.get("https://dropintaxi.com/server.php?task=get_distance",
					{
						from:$("input[name='from']").val(),
						to:$("input[name='to']").val()
					},
					function(data)
					{
						if(data)
						{
							try
							{
								var arr = JSON.parse(data);
							
								if(arr['distance'] != null)
								{
									if(arr['distance']>130)
									{
										setDistance(arr['distance']);
									}
									else
									{
										setDistance(130);
									}
								}
								else
								{
									setDistance(130);
								}
								
							}
							catch(err)
							{
								alert(err.message);
							}
							
						}
					});
			  }
		  }
		  
			
	  }
	
	const show_booking = (e) =>
	{
		 if($("input[name='from']").val() != $("input[name='to']").val())
		  {
				if($("input[name='from']").val() == "")
				{
					swal ( "Oops" ,  "Please Choose From City" ,  "error" ).then((res) => {
						$("input[name='from']").focus();
					});
					
				}
				else if($("input[name='to']").val() == "")
				{
					swal ( "Oops" ,  "Please Choose To City" ,  "error" ).then((res) => {
						$("input[name='to']").focus();
					});
					
				}
				else if($("select[name='car_type']").val() != "")
				{
					$(".ds-booking-section").removeClass("d-none");
					$('input[name="car_type"]').focus();
				}
				else
				{
					$(".ds-booking-section").removeClass("d-none");
					$('input[name="mobile"]').focus();
				}
		  }
		  else
		  {
			  swal ( "Oops" ,  "From and to are Same city entered" ,  "error" );
		  }
	}
	
	const bookingSubmit = (e) =>
	{
	  e.preventDefault();
		if($("input[name='from']").val() != $("input[name='to']").val())
		{
		  
				if($("input[name='from']").val() == "")
				{
					swal ( "Oops" ,  "Please Choose From City" ,  "error" ).then((res) => {
							$("input[name='from']").focus();
						});
					
				}
				else if($("input[name='to']").val() == "")
				{
					swal ( "Oops" ,  "Please Choose To City" ,  "error" ).then((res) => {
							$("input[name='to']").focus();
						});
				}
				else 
				{
					var form = e.target;
					var data = new FormData(form);
					$("#ds_btn_submit").hide();
					$("#ds_btn_submit").text("Please wait...");
					$(".ds-car-loading").removeClass("d-none");
					var whatsapp_msg = 'New Booking '+"\r\n";
					whatsapp_msg = whatsapp_msg + '---------------------------'+"\r\n";
					$("input,select").each(
					function()
					{
						var name = $(this).attr("name");
						var value = $(this).val();
						
						if(name == "mobile")
						{
							whatsapp_msg = whatsapp_msg + name +" : "+value+", "+"\r\n";
						}
						else if(name == 'from')
						{
							
						}
						else if(name == 'to')
						{
							
						}
						else
						{
							whatsapp_msg = whatsapp_msg + name +" : "+value+", "+"\r\n";
						}
						
					});
					
					whatsapp_msg = whatsapp_msg + '---------------------------'+"\r\n";
					whatsapp_msg = whatsapp_msg + 'For Booking https://www.dropintaxi.com'+"\r\n";
					
					
					$.ajax({
							type:'POST',
							url: e.target.getAttribute('action'),
							data:data,
							cache:false,
							contentType: false,
							processData: false,
							beforeSend:function()
							{
								
							},
							success:function(data){
								
								  $(".ds-car-loading").addClass("d-none");
								  $("#ds_btn_submit").fadeIn();
								  $("#ds_btn_submit").text("Confirm Booking");
								  
							   try
							   {
								  
								  var arr = JSON.parse(data);
								  if(arr.result == 1)
								  {
									  $("form")[0].reset();
									  window.location.href = "https://wa.me/919884444483?text="+window.encodeURIComponent(whatsapp_msg);
									  swal ( "Success" ,  "Booking Completed Please wait, Dropataxi will Call you soon" ,  "success" );
								  }
								  else
								  {
									  swal ( "Oops" ,  "Booking Not Success Please Try Again" ,  "error" );
								  }
							   }
							   catch(err)
							   {
								   console.log("Exception : "+err.message);
							   }
							},
							error: function(data){
								console.log("error");
								console.log(data);
								swal("Oops","Problem in Internet Connection or Server Please Try again","error");
							}
						});
						
					 $("#ds_btn_submit").fadeIn();
				}
		}
		else
		{
			swal ( "Oops" ,  "From and to are Same city entered" ,  "error" );
		}
	}
	
	const call_click = (e) =>
	{
		alert("You Can Call Now");
		$.get("https://dropintaxi.com/server.php?task=call_click",
			function(data)
			{
				if(data)
				{
					try
					{
						
					}
					catch(err)
					{
						
					}
					
				}
			});
	}

	
	
   return(
   <>
	<div class="row">
		<div class="col-lg-4"></div>
		<div class="col-lg-4">
			<div id="map"></div>
			<form action="https://dropintaxi.com/server.php?task=booking_insert" id="frm_book"  onSubmit={bookingSubmit}>
			<div class="ds-form-section mt-2 mb-2">
				<div class="row">
					<div class="col-6" align="center">
						<Link to="tel:+919884444483" class="btn btn btn-dark" onClick={call_click}>
							<i class="fa fa-phone fa-spin"></i>&nbsp;&nbsp;&nbsp;Call to Book  
						</Link>
					</div>
					<div class="col-6" align="center">
						<Link to="https://wa.me/919884444483?text=Hi%20Dropintaxi.com%20Have%20a%20nice%20Day%20God%20Bless%20Us" class="btn btn btn-success" onClick={call_click}>
							<i class="fab fa-whatsapp"></i>&nbsp;&nbsp;&nbsp;Whatsapp 
						</Link>
					</div>
				</div>
				<div align="center">or</div>
				<h1 class="fs-4 mt-2" align="center">Book Your Best Ride With Dropintaxi.com</h1>
				<div class="row m-0">
					<div class="col-3 p-1" align="center">
						<Link to="/" class="ds-trip-btn btn btn-warning ds-btn">Drop</Link>
					</div>
					<div class="col-3 p-1" align="center">
						<Link to="/round" class="ds-trip-btn btn btn-dark ds-btn">Round</Link>
					</div>
					<div class="col-3 p-1" align="center">
						<Link to="/local" class="ds-trip-btn btn btn-dark ds-btn">Local</Link>
					</div>
					<div class="col-3 p-1" align="center">
						<Link to="/rental" class="ds-trip-btn btn btn-dark ds-btn">Rental</Link>
					</div>
				</div>
				<div class="row m-0 mb-2">
					<div class="col-6">
						
							<label><i className="fa fa-play"></i> From</label>
							<input name="from" className="d-none"  type="text"/>
							<GooglePlacesAutocomplete 
							selectProps={{
							  From,
							  onChange: function(data,details)
							  {
								  setFrom(data.value.description);
								  //swal ( "You Ready from " ,  From ,  "info" );
								  $("input[type='from']").val(data.value.description);
								  //console.log(data);
								  setTimeout(function()
								  {
									setFromLocation();
								  },300);
							  },
							}} 
							autocompletionRequest={{
								bounds: [
								  { lat: 50, lng: 50 },
								  { lat: 100, lng: 100 }
								],
								componentRestrictions: {
								country: ['in'],
								}
							  }}
							  className ="form-control mt-1"
							  apiKey="AIzaSyC4sysfZmuU2TVsk8shEPTajKHp4_HuYLY"
							/>
					</div>
					<div class="col-6">
						
							<label><i className="fa fa-stop"></i> To</label>
							<input name="to" className="d-none" type="text"/>
							<GooglePlacesAutocomplete selectProps={{
							  To,
							  onChange: setTo,
							}}
								selectProps={{
								  To,
								  onChange: function(data,details)
								  {
										setTo(data.value.description);
										//swal ( "You will go to " ,   ,  "info" );
										$("input[type='to']").val(data.value.description);
										
										//console.log(data);
										  setTimeout(function()
										  {
											setFromLocation();
										  },300);
								  },
								}} 
								autocompletionRequest={{
								bounds: [
								  { lat: 50, lng: 50 },
								  { lat: 100, lng: 100 }
								],
								componentRestrictions: {
								country: ['in'],
								}
							  }}
								className="form-control mt-1"
							  apiKey="AIzaSyC4sysfZmuU2TVsk8shEPTajKHp4_HuYLY"
							/>
					</div>
				</div>
				<div class="row m-0 mb-2">
					<div class="col-12 d-none">
						<select name="car_type" id="sel-car-type" class="form-control ds-minimal form-select mt-1">
							<option  value="">Choose...</option>
							<option  value="SEDAN">SEDAN</option>
							<option  value="ETIOS">ETIOS</option>
							<option  value="SUV">SUV</option>
							<option  value="INNOVA">INNOVA</option>
						</select>
					</div>
				</div>
				
				<div class="ds-car-types ds-car-images">
					<label><i class="fa fa-car"></i> Select Car Types</label>
					<div class="row">
						<div class="col-6 p-0" align="center">
							<div class="ds-car selected">
								<div class="badge badeg-dark text-dark fs-6">₹14 per km</div>
								<img src="sedan.png" data-rate-per-km="14" data-value="SEDAN" onClick={choose_car_type} width="100%"/>
								<div class="badge badeg-dark text-dark fs-5">SEDAN</div>
							</div>
						</div>
						<div class="col-6 p-0" align="center">
							<div class="ds-car">
								<div class="badge badeg-dark text-dark fs-6">₹15 per km</div>
								<img src="etios.png" data-rate-per-km="15" data-value="ETIOS" onClick={choose_car_type} width="100%"/>
								<div class="badge badeg-dark text-dark fs-5">ETIOS</div>
							</div>
						</div>
						<div class="col-6 p-0" align="center">
							<div class="ds-car">
								<div class="badge badeg-dark text-dark fs-6">₹19 per km</div>
								<img src="suv.png" data-rate-per-km="19" data-value="SUV" onClick={choose_car_type} width="100%"/>
								<div class="badge badeg-dark text-dark fs-5">SUV</div>
							</div>
						</div>
						<div class="col-6 p-0" align="center">
							<div class="ds-car">
								<div class="badge badeg-dark text-dark fs-6">₹20 per km</div>
								<img src="innova.png"  data-rate-per-km="20" data-value="INNOVA" onClick={choose_car_type} width="100%"/>
								<div class="badge badeg-dark text-dark fs-5">INNOVA</div>
							</div>
						</div>
					</div>
				</div>
				
					<input class="ds-not-display d-none"  id="hid_trip_type" type="text" name="trip_type" value="Dropout"/>
					<input class="ds-not-display d-none"  id="hid_rate_per_km" type="text" name="rate_per_km" value={RatePerKm}/>
					<input class="ds-not-display d-none"  id="hid_driver_bata" type="text" name="driver_bata" value={DriverBata}/>
					<input class="ds-not-display d-none"  id="hid_base_charge" type="text" name="base_charge" value={BaseCharge}/>
					<input class="ds-not-display d-none"  id="hid_total_rate" type="text" name="total_rate" value={TotalRate}/>
				
				<div class="ds-cost-estimation" align="center">
					<h3>Approx* Travel in {CarType}</h3>
					<h4 class="text-warning">Rate is ₹{TotalRate} per minimum {Distance}km</h4>
					<img id="ds-selected-car" src="" class="d-none" width="100%"/>
					<div class="m-1 fs-5 text-success">Base charges: ₹{BaseCharge} / Driver bata: ₹{DriverBata} + Toll at actuals</div>
					<p class="text-danger">*Inclusive of GST. The actual bill might differ based on actual distance travelled, waiting time (for droptrips), hill-station charges & inter-state permits.</p>
					
					<div class="row">
						<div class="col-12" align="center">
							<div class="btn btn-dark btn-block ds-btn" onClick={show_booking}><i className="fa fa-arrow-right"></i> Click to Book</div> or <Link to="tel:+919884444483" class="btn btn-dark btn-block ds-btn" onClick={call_click}><i className="fa fa-phone"></i> Call to Book</Link>
						</div>
					</div>
				</div>
				
				<div class="ds-booking-section d-none m-3">
					<div class="row">
						<div class="col-12">
							<label class="mt-1">*<i class="fa fa-mobile"></i> Mobile Number</label>
							<input id="txt-mobile" min="1" type="number" placeholder="" name="mobile" class="form-control mt-1" required/>
							<label class="mt-1">*<i class="fa fa-user"></i> Your Name</label>
							<input type="text" placeholder="" name="name" class="form-control mt-1" required/>
							<label class="mt-1">*<i class="fa fa-calendar"></i> Travel Date</label>
							<input type="date" name="date" class="form-control mt-1" required/>
							<label class="mt-1">*<i class="fa fa-clock"></i> Travel Time</label>
							<select name="time" class="form-control form-select mt-1" required>
								<option>Choose...</option>
								<option value="12:00 am">12:00 am</option>
								<option value="12:30 am">12:30 am</option>
								<option value="1:00 am">1:00 am</option>
								<option value="1:30 am">1:30 am</option>
								<option value="2:00 am">2:00 am</option>
								<option value="2:30 am">2:30 am</option>
								<option value="3:00 am">3:00 am</option>
								<option value="3:30 am">3:30 am</option>
								<option value="4:00 am">4:00 am</option>
								<option value="4:30 am">4:30 am</option>
								<option value="5:00 am">5:00 am</option>
								<option value="5:30 am">5:30 am</option>
								<option value="6:00 am">6:00 am</option>
								<option value="6:30 am">6:30 am</option>
								<option value="7:00 am">7:00 am</option>
								<option value="7:30 am">7:30 am</option>
								<option value="8:00 am">8:00 am</option>
								<option value="8:30 am">8:30 am</option>
								<option value="9:00 am">9:00 am</option>
								<option value="9:30 am">9:30 am</option>
								<option value="10:00 am">10:00 am</option>
								<option value="10:30 am">10:30 am</option>
								<option value="11:00 am">11:00 am</option>
								<option value="11:30 am">11:30 am</option>
								<option value="12:00 pm">12:00 pm</option>
								<option value="12:30 pm">12:30 pm</option>
								<option value="1:00 pm">1:00 pm</option>
								<option value="1:30 pm">1:30 pm</option>
								<option value="2:00 pm">2:00 pm</option>
								<option value="2:30 pm">2:30 pm</option>
								<option value="3:00 pm">3:00 pm</option>
								<option value="3:30 pm">3:30 pm</option>
								<option value="4:00 pm">4:00 pm</option>
								<option value="4:30 pm">4:30 pm</option>
								<option value="5:00 pm">5:00 pm</option>
								<option value="5:30 pm">5:30 pm</option>
								<option value="6:00 pm">6:00 pm</option>
								<option value="6:30 pm">6:30 pm</option>
								<option value="7:00 pm">7:00 pm</option>
								<option value="7:30 pm">7:30 pm</option>
								<option value="8:00 pm">8:00 pm</option>
								<option value="8:30 pm">8:30 pm</option>
								<option value="9:00 pm">9:00 pm</option>
								<option value="9:30 pm">9:30 pm</option>
								<option value="10:00 pm">10:00 pm</option>
								<option value="10:30 pm">10:30 pm</option>
								<option value="11:00 pm">11:00 pm</option>
								<option value="11:30 pm">11:30 pm</option>
							</select>
							<label class="mt-1"><i class="fa fa-question"></i> How did you know</label>
							<select name="how_did_you_know" class="form-control form-select mt-1">
								<option value="">Choose...</option>
								<option value="Facebook">Facebook</option>
								<option value="Youtube">Youtube</option>
								<option value="Google">Google</option>
								<option value="Newspaper">Newspaper</option>
								<option value="Notice">Notice</option>
								<option value="Friends">Friends</option>
							</select>
						</div>
					</div>
					
					<div class="ds-car-loading p-2 d-none" align="center">
							<img src="car-loading.gif" width="100px" class="ds-img-circle"/>
							<div class="fw-bold">Please wait...</div>
					</div>
						
					<div class="btn-block mt-4" align="center">
						<button type="submit" class="btn btn-dark btn-block ds-btn m-1"><i className="fa fa-arrow-right"></i> Confirm to Book</button> <div>or</div> <Link onClick={call_click} to="tel:+919884444483" class="btn btn-dark btn-block ds-btn m-1" onClick={call_click}><i className="fa fa-phone"></i> Call to Book</Link>
					</div>
				</div>
				
				
			</div>
			</form>
		</div>
		<div class="col-lg-4"></div>
	</div>
   </>);
}



$(document).ready(
function()
{
	$('input[name="from"]').select();
	$('input[name="to"]').select();
	
	$("#react-select-3-input").on("change",function()
	{
		//setFromLocation();
	});
	
	$("#react-select-5-input").on("change",function()
	{
		//setFromLocation();
	});
});

export default Main;


	