const Traffic = () => {

      return (
        <div>
          {/*?php include("header.php")?*/}
          <div className="sidebar-popup">
            <div className="sidebar-wrapper">
              <div className="sidebar-content">
                <button type="button" className="close-sidebar-popup"><i className="far fa-xmark" /></button>
                <div className="sidebar-logo">
                  {/* <img src="assets/img/logo/logo.png" alt> */}
                  <h4>DROP IN <span style={{color: '#FDB200'}}>TAXI</span></h4>
                </div>
                <div className="sidebar-about">
                  <h4>About Us</h4>
                  <p>There are many variations of passages available sure there majority have suffered alteration in
                    some form by injected humour or randomised words which don't look even slightly believable.</p>
                </div>
                <div className="sidebar-contact">
                  <h4>Contact Info</h4>
                  <ul>
                    <li>
                      <h6>Email</h6>
                      <a href><i className="far fa-envelope" /><span className="__cf_email__">info@dropintaxi.com</span></a>
                    </li>
                    <li>
                      <h6>Phone</h6>
                      <a href="tel:+21236547898"><i className="far fa-phone" />+91 98844 44483</a>
                    </li>
                    <li>
                      <h6>Address</h6>
                      <a href="#"><i className="far fa-location-dot" />CHENNAI</a>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-social">
                  <h4>Follow Us</h4>
                  <a href="#"><i className="fab fa-facebook" /></a>
                  <a href="#"><i className="fab fa-twitter" /></a>
                  <a href="#"><i className="fab fa-instagram" /></a>
                  <a href="#"><i className="fab fa-linkedin" /></a>
                </div>
              </div>
            </div>
          </div>
          <main className="main">
            <div className="site-breadcrumb" style={{background: 'url(http://dropintaxi.com/assets/img/breadcrumb/01.jpg)'}}>
              <div className="container">
                <h2 className="breadcrumb-title">Tariff</h2>
                <ul className="breadcrumb-menu">
                  <li><a href="/">Home</a></li>
                  <li className="active">Tariff</li>
                </ul>
              </div>
            </div>
            <section className="tariff_section hero-section ">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="py-3">Tariff – Chart :</h3>
                    <h5 className="py-3">Drop In Taxi – outstation Trips :</h5>
                    <table width="100%" className="tariff_table table table-striped ">
                      <tbody>
                        <tr className="table-warning">
                          <th width="40%" style={{fontWeight: 'bold'}}>Vehicle Type</th>
                          <th width="30%" style={{fontWeight: 'bold'}}>Round Trip Rate/km</th>
                          <th width="30%" style={{fontWeight: 'bold'}}>Drop Trip Rate/km</th>
                        </tr>
                        <tr>
                          <td> Sedan (aspire,dzire,xcent,etios)</td>
                          <td>Rs.12</td>
                          <td>Rs.14</td>
                        </tr> <tr>
                          <td> SUV (xylo,tavera,enjoy,innova)</td>
                          <td>Rs. 17</td>
                          <td>Rs. 18</td>
                        </tr> <tr>
                          <td> INNOVA (assured)</td>
                          <td>Rs. 18</td>
                          <td>Rs. 19</td>
                        </tr> <tr>
                          <td> ETIOS (assured)</td>
                          <td>Rs. 13</td>
                          <td>Rs. 15</td>
                        </tr>
                        <tr>
                          <td>CRYSTA (assured)</td>
                          <td>Rs. 20</td>
                          <td>Nail</td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="info">
                            <p>Rates given above are included GST</p>
                            <p>Drop Trip - driver batta Rs.400. [above 400km for Rs.600] - waiting Charges Rs.2 per minutes</p>
                            <p>Drop Trip Maximum Running must be 130 km per day.</p>
                            <p>Round Trip – Driver Batta Rs.500 per day.</p>
                            <p>Round Trip Maximum Running must be 250 km per day.</p>
                            <p>Hill Station Charges – Rs.300 fer sedan Rs.400 for SUV.</p>
                            <p>Luggage Charges Rs.300 fore more titles please visit luggage policy.</p>
                            <p>Toll fees, inter state permit Charges (if eny) or extra.</p>
                            <p>One day means Calendar day (from 00.00 hrs to 23.59hr)</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h5 className="py-3">Tembo Traveller :</h5>
                    <table width="100%" className="tariff_table table table-striped ">
                      <tbody>
                        <tr className="table-warning">
                          <th style={{fontWeight: 'bold'}}>Trip Info</th>
                          <th style={{fontWeight: 'bold'}}>Rate</th>
                        </tr>
                        <tr className>
                          <th width="40%" style={{fontWeight: 'bold'}}>Round Trip only </th>
                          <th width="30%" style={{fontWeight: 'bold'}}>Rs.22 / km</th>
                        </tr>
                        <tr className>
                          <th width="40%" style={{fontWeight: 'bold'}}>Driver Batta </th>
                          <th width="30%" style={{fontWeight: 'bold'}}>Rs.700</th>
                        </tr>
                        <tr>
                          <td colSpan={3} className="info">
                            <p>Round Trips – Maximum Running must be 250 km per days</p>
                            <p>Toll fees, inter state permit Charges (ifeny) or extra.</p>
                            <p>One day means alendar day (from 00.00 hrs to 23.59hr)</p>
                            <p>For Bookings pls call or WhatsApp no (+91 9884444483)</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h5 className="py-3">Drop In Taxi – Local packages</h5>
                    <table width="100%" className="tariff_table table table-striped ">
                      <tbody>
                        <tr className="table-warning">
                          <th style={{fontWeight: 'bold'}}>Hours </th>
                          <th style={{fontWeight: 'bold'}}>Kms</th>
                          <th style={{fontWeight: 'bold'}}>SEDAN</th>
                          <th style={{fontWeight: 'bold'}}>SUV</th>
                          <th style={{fontWeight: 'bold'}}>INNOVA</th>
                          <th style={{fontWeight: 'bold'}}>CRYSTA</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>5 Hours</th>
                          <th style={{fontWeight: 'bold'}}>Kms 50</th>
                          <th style={{fontWeight: 'bold'}}>Rs.1400 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.1850</th>
                          <th style={{fontWeight: 'bold'}}>Rs.2000</th>
                          <th style={{fontWeight: 'bold'}}>Rs.2200</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>10 Hours</th>
                          <th style={{fontWeight: 'bold'}}>Kms 100</th>
                          <th style={{fontWeight: 'bold'}}>Rs.2800 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.3600</th>
                          <th style={{fontWeight: 'bold'}}>Rs.3900</th>
                          <th style={{fontWeight: 'bold'}}>Rs.4300</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>15 Hours</th>
                          <th style={{fontWeight: 'bold'}}>Kms 150</th>
                          <th style={{fontWeight: 'bold'}}>Rs.4000 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.5400</th>
                          <th style={{fontWeight: 'bold'}}>Rs.5600</th>
                          <th style={{fontWeight: 'bold'}}>Rs.6500</th>
                        </tr>
                        <tr className>
                          <th colSpan={2} style={{fontWeight: 'bold'}}>Add Hours</th>
                          <th style={{fontWeight: 'bold'}}>Rs.250 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.350</th>
                          <th style={{fontWeight: 'bold'}}>Rs.390</th>
                          <th style={{fontWeight: 'bold'}}>Rs.450</th>
                        </tr>
                        <tr className>
                          <th colSpan={2} style={{fontWeight: 'bold'}}>Add Km</th>
                          <th style={{fontWeight: 'bold'}}>Rs.20 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.25</th>
                          <th style={{fontWeight: 'bold'}}>Rs.28</th>
                          <th style={{fontWeight: 'bold'}}>Rs.30</th>
                        </tr>
                      </tbody>
                    </table>
                    <h5 className="py-3">Drop In Taxi – Outstation packages</h5>
                    <table width="100%" className="tariff_table table table-striped ">
                      <tbody>
                        <tr className="table-warning">
                          <th style={{fontWeight: 'bold'}}>Hours </th>
                          <th style={{fontWeight: 'bold'}}>Kms</th>
                          <th style={{fontWeight: 'bold'}}>SEDAN</th>
                          <th style={{fontWeight: 'bold'}}>SUV</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>6 Hours</th>
                          <th style={{fontWeight: 'bold'}}>Kms 100</th>
                          <th style={{fontWeight: 'bold'}}>Rs.2000 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.3000</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>10 Hours</th>
                          <th style={{fontWeight: 'bold'}}>Kms 190</th>
                          <th style={{fontWeight: 'bold'}}>Rs.3000 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.4000</th>
                        </tr>
                        <tr className>
                          <th colSpan={2} style={{fontWeight: 'bold'}}>Add Hours</th>
                          <th style={{fontWeight: 'bold'}}>Rs.200</th>
                          <th style={{fontWeight: 'bold'}}>Rs.300</th>
                        </tr>
                        <tr className>
                          <th colSpan={2} style={{fontWeight: 'bold'}}>Add Km</th>
                          <th style={{fontWeight: 'bold'}}>Rs.12</th>
                          <th style={{fontWeight: 'bold'}}>Rs.18</th>
                        </tr>
                      </tbody>
                    </table>
                    <h5 className="py-3">Permit Charges, (Adarsh State)</h5>
                    <table width="100%" className="tariff_table table table-striped ">
                      <tbody>
                        <tr className="table-warning">
                          <th style={{fontWeight: 'bold'}}>Vehicle Tips</th>
                          <th style={{fontWeight: 'bold'}}>TRAVELLER(13+1)</th>
                          <th style={{fontWeight: 'bold'}}>SEDAN(Dzire,xcent)</th>
                          <th style={{fontWeight: 'bold'}}>SUV(Innova,Xylo)</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>Pondy</th>
                          <th style={{fontWeight: 'bold'}}>Rs.1200</th>
                          <th style={{fontWeight: 'bold'}}>Rs.400 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.900</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>Karnadaga</th>
                          <th style={{fontWeight: 'bold'}}>Rs.3500</th>
                          <th style={{fontWeight: 'bold'}}>Rs.800 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.1200</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>Andhra</th>
                          <th style={{fontWeight: 'bold'}}>Rs.3400</th>
                          <th style={{fontWeight: 'bold'}}>Rs.400 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.2200</th>
                        </tr>
                        <tr className>
                          <th style={{fontWeight: 'bold'}}>Kerala</th>
                          <th style={{fontWeight: 'bold'}}>Rs.3200</th>
                          <th style={{fontWeight: 'bold'}}>Rs.350 </th>
                          <th style={{fontWeight: 'bold'}}>Rs.500</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </main>
          {/*?php include("footer.php")?*/}
        </div>
      );
    }
export default Traffic